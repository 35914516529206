



























































































import { defineComponent, PropType, provide, reactive } from "@vue/composition-api";
import { getStudent } from "@/api/customerService";
import { userModule } from "@/store/modules/user";
import CreateStudentDialog from "@/components/customer/my-students-customer/CreateStudentDialog.vue";
import DeleteStudentDialog from "@/components/customer/my-students-customer/DeleteStudentDialog.vue";
import StudentAchievementsParentDialog from "@/components/achievements/StudentAchievementsParentDialog.vue";
import useAsyncData from "@/hooks/useAsyncData";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  name: "StudentCard",
  components: { StudentAchievementsParentDialog, DeleteStudentDialog, CreateStudentDialog, ProfilePhoto },
  props: {
    studentUID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      deleteStudentDialogVisible: false,
      placeholder: "",
      studentAchievementsDialogVisible: false,
      updateStudentDialogVisible: false,
    });

    const employee = userModule.state.user.employee;
    const { isLoading, data: student, refresh } = useAsyncData(() => getStudent(props.studentUID));

    function deleteStudent() {
      emit("deleted");
    }

    provide("getStudent", refresh);

    return {
      betaTester: userModule.state.user.betaTester,
      deleteStudent,
      employee,
      isLoading,
      student,
      vm,
    };
  },
});
