








































import { computed, defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "DeleteStudentDialog",
  props: {
    student: {
      type: Object as PropType<Learnlink.Student.default>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const achievementCount = computed(() => props.student.user.achievements?.length);
    const studentName = computed(() => props.student.profile?.firstName);
    return {
      _value,
      achievementCount,
      emit,
      studentName,
    };
  },
});
