


















import CreateStudentCard from "@/components/customer/my-students-customer/CreateStudentCard.vue";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CreateStudentDialog",
  components: { CreateStudentCard },
  props: {
    parentUID: {
      type: String as PropType<string>,
      default: "",
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    student: {
      type: Object as PropType<Learnlink.Student.default>,
      default: () => ({}),
    },
    teamID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      _value,
    };
  },
});
