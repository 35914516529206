














































































































import { createStudent } from "@/api/customerService";
import { userModule } from "@/store/modules/user";
import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import { updateAccount, updateProfile } from "@/api/userService";
import { updateAuthObject } from "@/api/authService";
import { useNotifier } from "@/providers/notifier";
import handleError, { sanitizeError } from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import rules from "@/helpers/formRules";

export default defineComponent({
  name: "CreateStudentCard",
  props: {
    parentUID: {
      type: String as PropType<string>,
      default: "",
    },
    student: {
      type: Object as PropType<Learnlink.Student.default>,
      default: () => ({}),
    },
    teamID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      completedForm: false,
      loading: false,
      showPassword: false,
      student: {
        email: "",
        firstName: props.student?.profile?.firstName || "",
        lastName: props.student?.profile?.lastName || "",
        password: "",
        parentUID: props.parentUID,
        phone: props.student.account?.phone || "" as string | undefined,
        teamID: props.teamID || undefined,
      } as Learnlink.Student.Create,
    });
    const notify = useNotifier();

    const getStudent = !props.parentUID
      ? inject<() => Promise<void>>("getStudent")
      : undefined;
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");

    onMounted(() => {
      if (!props.student.account?.email.includes("@learnlink") || userModule.state.account.email.includes("@learnlink")) {
        vm.student.email = props.student.account?.email || "";
      }
    });

    async function submit() {
      vm.loading = true;
      try {
        if (props.parentUID) {
          if (vm.completedForm) {
            const student = vm.student;
            student.phone = vm.student.phone || undefined;
            if (vm.student.phone) {
              vm.student.phone = vm.student.phone.replaceAll(" ", "");
            }
            await createStudent(student);
            emit("created");
            getMyTeams && await getMyTeams();
            notify({
              title: "Wohooo! 🥳",
              message: "Ditt barn kan nå logge inn på egen bruker, og koble til Discord for å kunne prate med oss der.",
              type: "success",
            });
            emit("close");
          }
          else {
            notify({
              title: "Noe er ikke som det skal! 🧐",
              message: "Det virker som om det mangler noe informasjon. Se til at de obligatoriske feltene er fylt ut riktig.",
              type: "error",
            });
          }
        }
        else {
          if (vm.student.password && vm.completedForm) {
            await updateAuthObject({
              uid: props.student.user?.uid,
              email: vm.student.email,
              password: vm.student.password,
            });
          }
          if (vm.completedForm) {
            vm.student.phone = vm.student.phone || undefined;
            if (vm.student.phone) {
              vm.student.phone = vm.student.phone.replaceAll(" ", "");
            }
            await updateAccount({
              uid: props.student.user?.uid,
              email: vm.student.email,
              phone: vm.student.phone,
            });
            await updateProfile({
              uid: props.student.user?.uid,
              firstName: vm.student.firstName,
              lastName: vm.student.lastName,
            });
            if (getStudent) await getStudent();
            notify({
              title: "Wohooo! 🥳",
              message: "Oppdateringen gikk som smurt!",
              type: "success",
            });
            emit("close");
          }
          else {
            notify({
              title: "Noe er ikke som det skal! 🧐",
              message: "Det virker som om det mangler noe informasjon. Se til at de obligatoriske feltene er fylt ut riktig.",
              type: "error",
            });
          }
        }
      }
      catch (e) {
        handleError(e);
        const sanitizedError = sanitizeError(e);
        if (sanitizedError.code === 409 && sanitizedError.message.includes("email")) {
          notify({
            title: "E-postadressen er allerede i bruk! 🤯",
            message: "Prøv med en annen e-post, eller kontakt support dersom dette ikke stemmer.",
            type: "error",
          });
        }
        else if (sanitizedError.code === 422) {
          let params: string;
          if (Array.isArray(sanitizedError.message)) {
            params = sanitizedError.message.map(message => {
              if (message.param === "phone") return "Telefonnummeret";
              if (message.param === "email") return "E-postadressen";
              return "";
            }).join(", ");
          }
          else {
            params = "Noe av informasjonen";
          }
          notify({
            title: params + " er ikke gyldig. 🤯",
            message: "Dobbeltsjekk at all informasjonen stemmer.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Noe gikk galt! 🤯",
            message: "Vennligst kontakt support hvis problemet vedvarer.",
            type: "error",
          });
        }
      }
      vm.loading = false;
    }
    return {
      rules,
      submit,
      vm,
    };
  }
});
