


































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { deleteStudent } from "@/api/authService";
import { getUser } from "@/api/userService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import StudentCard from "@/components/customer/my-students-customer/StudentCard.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "MyStudentsCustomer",
  components: { StudentCard },
  setup() {
    const vm = reactive({
      loading: false,
    });

    const { data: user, refresh } = useAsyncData(() => getUser(userModule.state.userId));

    const studentList = computed(() => user.value?.students || []);
    const parentUID = userModule.state.userId;
    const notify = useNotifier();

    async function deleteStudentUser(student: string) {
      vm.loading = true;
      try {
        await deleteStudent(student);
        notify({
          title: "Slettet! 🥳",
          message: "Brukeren til barnet ditt er nå slettet.",
          type: "success",
        });
        await refresh();
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Vennligst kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }
    return {
      deleteStudentUser,
      refresh,
      studentList,
      parentUID,
      vm,
    };
  },
});
