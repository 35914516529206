














import StudentAchievementsCustomer from "@/components/customer/my-students-customer/StudentAchievementsCustomer.vue";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "StudentAchievementsParentDialog",
  components: { StudentAchievementsCustomer },
  props: {
    student: {
      type: Object as PropType<Learnlink.Student.default>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    return {
      _value,
    };
  }
});
